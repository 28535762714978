<template>
    <div>

        <!--case number verification modal-->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Case Request Verification" class="modal-dark" v-model="areYouSureRequest">
            <p>Enter "{{newRequest.caseNumber}}":</p>
            <b-form-group>
                <b-form-input v-model="requestsVerifications" id="basicName3" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="areYouSureRequest=false">Cancel</b-button>
                <b-button size="sm" :disabled="requestsVerifications !== newRequest.caseNumber" variant="danger" @click="PostCaseRequest">Submit</b-button>
            </template>
        </b-modal>

        <!-- new case request modal-->
        <b-modal  title="New Case Request" class="modal-dark" v-model="makeRequestModal">
          <span class="d-flex align-items-center" style="margin-bottom: 10px">
            <p style="width: fit-content; margin: 0">Case Number:&nbsp;</p>
            <b-form-input no-close-on-backdrop scrollable
                          class="modal-dark"
                          style="width: inherit; flex-grow: 1;"
                          :style="newRequest.caseNumber ? '' : 'border-color: #dc3545'"
                          v-model="newRequest.caseNumber"
                          oncopy="return false" onpaste="return false"
                          type="text" size="sm">
            </b-form-input>
          </span>
          <span class="d-flex align-items-center">
            <p style="width: fit-content; margin: 0">Confirm Case Number:&nbsp;</p>
            <b-form-input no-close-on-backdrop scrollable
                          class="modal-dark"
                          style="width: inherit; flex-grow: 1"
                          :style="requestsVerifications === newRequest.caseNumber && newRequest.caseNumber ? '' : 'border-color: #dc3545'"
                          v-model="requestsVerifications"
                          oncopy="return false" onpaste="return false"
                          type="text" size="sm">
            </b-form-input>
          </span>
           <template slot="modal-footer">
               <b-button size="sm" variant="secondary" @click="makeRequestModal=false">Cancel</b-button>
               <b-button size="sm" variant="primary" :disabled="requestsVerifications !== newRequest.caseNumber || !newRequest.caseNumber" @click="PostCaseRequest">Submit</b-button>
           </template>
        </b-modal>

        <!--New User Request-->
        <b-modal  title="User Request" class="modal-dark" v-model="userRequestModal">
            <label> Select User</label>
                <b-form-group
                    :horizontal="true">
                        <b-form-select v-model="selectedUser" id="basicSelect"
                            :plain="true"
                            :options="users.map(function(i) {
                                return{text: i.username,value:i}
                            })"
                             size="sm">
                        </b-form-select>
                </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="userRequestModal=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="CreateAUserRequest">submit</b-button>
            </template>
        </b-modal>

        <!--case evidence modal -->
        <b-modal v-if="currentCase.evidence && currentCase.evidence.length > 0" v-model="caseEvidenceModal"
                 :title="'Case ' + currentCase.caseNumber + ' Evidence'" class="modal-dark" size="xl">
          <div style="overflow-x: auto">
            <span class="d-flex flex-row justify-content-between" style="min-width: 1006px">

              <!--Number of Files-->
              <span>
                Files: {{currentCase.evidence.length}}
              </span>

              <!--Download Case-->
              <span>
                <fa-icon :icon="['fas', 'download']" size="lg" @click="downloadCaseFiles(currentCase)" style="cursor: pointer;"/>&nbsp;
                (Download Case)
              </span>

            </span>

            <!--Headings-->
            <b-card class="d-flex flex-row align-items-center justify-content-between flex-wrap" style="background: #1d1d1d; font-size: 15px; margin-bottom: 3px; min-width: 1006px" no-body>
              <strong style="margin-left: 3px">Case Type</strong>
              <div class="flex-grow-1 d-flex flex-row m-0 align-items-center flex-wrap">
                <b-col col="3"><strong>Date/Time</strong></b-col>
                <b-col col="4"><strong>Description</strong></b-col>
                <b-col col="3" style="text-align: center"><strong>Location</strong></b-col>
                <b-col col="2"><strong>Created By</strong></b-col>
              </div>
              <strong style="margin-right: 3px">Download</strong>
            </b-card>

            <!--Evidence Pieces for each case-->
            <b-card no-body v-for="(evidence, idx) in currentCase.evidence" :key="idx+'case'" style="margin: 0 0 3px 0; background-color: #2f353a; min-width: 1006px">

              <div class="d-flex flex-row w-100 m-0 align-items-center justify-content-between flex-wrap">

                <!--Case Type-->
                <div style="width: 73px; margin-left: 3px">
                  <div v-if="evidence.caseType">{{evidence.caseType}}</div>
                  <span v-else style="color: grey">no case type</span>
                </div>

                <div class="flex-grow-1 d-flex flex-row m-0 align-items-center flex-wrap" style="font-size: 13px">

                  <!--Date & Time-->
                  <b-col col="3" class="m-0">
                    <div v-if="evidence.evidenceDate && evidence.evidenceTime">{{formatDate(evidence.evidenceDate)}} {{evidence.evidenceTime}}</div>
                    <div v-else-if="evidence.evidenceDate">
                      {{formatDate(evidence.evidenceDate)}}
                      <span style="color: grey">No Time</span>
                    </div>
                    <div v-else-if="evidence.evidenceTime">
                      <span style="color: grey">No Date</span>
                      {{evidence.time}}
                    </div>
                    <span v-else style="color: grey">No Date or Time</span>
                  </b-col>

                  <!--Description-->
                  <b-col col="4" class="m-0">
                    <div v-if="evidence.description">{{evidence.description}}</div>
                    <span v-else style="color: grey">no description</span>
                  </b-col>

                  <!--Location and Download-->
                  <b-col col="3" class="m-0">
                    <div v-if="evidence.location.address !== ''" style="text-align: center">
                      <div>{{evidence.location.address}}</div>
                      <div> {{evidence.location.address2}}</div>
                      <div>{{evidence.location.city}}, {{evidence.location.state}} {{evidence.location.zip}}</div>
                    </div>
                    <span v-else style="color: grey; text-align: center">No Address</span>
                  </b-col>

                  <!--Created By-->
                  <b-col col="2" class="m-0">
                    <div v-if="evidence.description">{{evidence.uploadedBy}}</div>
                  </b-col>

                </div>

                <!--Download-->
                <div style="width: 71.6px; text-align: right">
                  <fa-icon :icon="['fas', 'download']" @click="downloadEvidencePieceFiles(evidence.fileId, evidence, idx)"
                           style="margin-right: 3px; cursor: pointer;"/>
                </div>

              </div>


            </b-card>
          </div>

        </b-modal>

        <!--User View-->
        <b-card header-tag="header">

            <!--Header-->
            <span slot="header" class="d-flex w-100 justify-content-between align-content-center">
                <h5 style="margin: 0"><b>Requests</b></h5>
                <fa-icon :icon="['fas', 'plus']" size="lg" style="cursor: pointer;" @click="openAddCaseRequest()"/>
            </span>

            <!--Request Table-->
            <v-client-table ref="dTable" style="width: auto" :columns="columns" :data="userCaseRequests" :options="tOptions" class="dataTable">
                <div slot="icon" slot-scope="props" style="float: right">
                    <fa-icon v-if="props.row.status === 'Approved'" :icon="['fas', 'folder-open']" style=" margin-left: 10px; cursor: pointer"
                             @click="openCaseEvidence(props.row)"/>&nbsp;
                    <fa-icon :icon="['fas', 'user-plus']" style="cursor: pointer;" @click="openAddUser(props.row)"/>
                </div>
                <div slot="created_date" slot-scope="props">
                    {{dateify(props.row.created_date)}}
                </div>
            </v-client-table>

        </b-card>

    </div>
</template>

<script>
    import digitalevidence from '../../../services/digital-evidence.js'
    import Iss from '../../../services/iss.js'
    import Vue from 'vue'
    import {ClientTable} from 'vue-tables-2'
    import * as moment from "moment";
    import JsZip from "jszip";
    import fcr from "@/services/fcr";

    Vue.use(ClientTable)

    export default {
        name: "UserView",

        data: () => {
            return {
                columns: ['caseNumber','status','created_date','requestedBy','icon'],
                tOptions: {
                    sortable: ['caseNumber', 'status', 'created_date', 'requestedBy'],
                    filterable: ['caseNumber', 'status', 'created_date', 'requestedBy'],
                    orderBy: {column: 'created_date', ascending: true},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    headings: {
                        caseNumber: 'Case Number',
                        status: 'Status',
                        created_date: 'Created Date',
                        requestedBy: 'Requested By',
                        icon: ' ',

                    },
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                newUser: {},
                requests: [],
                userCaseRequests:[],
                userRequestModal: false,
                makeRequestModal: false,
                caseEvidenceModal:false,
                areYouSureRequest: false,
                requestsVerifications: '',
                newRequest: {},
                currentCase:{},
                selectedUser:{},
                users:[],
                status:'',
                approved:'',

            }
        },

        created() {
            //this.GetAllApprovedUserCaseRequests();
            this.GetUserCaseRequests();
            this.GetUsersForRequest();
            //this.GetAllOtherUserCaseRequests();
            setInterval(() => {
                //this.GetAllApprovedUserCaseRequests();
                //this.GetAllOtherUserCaseRequests();
                this.GetUserCaseRequests();
            }, 30000);
        },

        methods: {
            downloadEvidencePieceFiles(fileId, report, index) {
                // downloads all the evidence from the fileId in a zip
                digitalevidence.downloadEvidence(fileId).then(res => {
                    const fileName = 'Case-' + report.caseNumber  + '-Evidence-' + (index + 1) + '.zip';
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }).catch(() => {
                    this.$mToast({
                        title: 'Error',
                        text: "Error downloading requested evidence, please contact support!",
                        style: 'error'
                    });
                });
            },
            downloadCaseFiles(report) {
                // creates and downloads a zip file
                // the zip file contains a zip file for each file ID entry associated with the case number
                // yes, there are nested zip files
                let evidenceCount = 0;
                let evidenceTotal = report.evidence.length;
                let zip = new JsZip();

                for (let i = 0; i < report.evidence.length; i++) {
                    digitalevidence.downloadEvidence(report.evidence[i].fileId).then(response => {
                        zip.file('Evidence-' + JSON.stringify(i + 1) + ".zip", response.data);
                        evidenceCount++;
                        if (evidenceCount === evidenceTotal) {
                            zip.generateAsync({
                                type: 'blob',
                                compression: 'DEFLATE'
                            }).then(res => {
                                const fileName = 'Case-' + report.caseNumber + '.zip';
                                const link = document.createElement('a');
                                link.href = window.URL.createObjectURL(res);
                                link.setAttribute('download', fileName);
                                document.body.appendChild(link);
                                link.click();
                            });
                        }
                    });
                }
            },
            formatDate(date) {
                return date.substring(0, date.indexOf('T'));
            },
            GetUserCaseRequests() {
                this.userCaseRequests = [];
                digitalevidence.GetAllApprovedUserCaseRequests().then(response => {
                    this.userCaseRequests = response.data;
                    digitalevidence.GetAllOtherUserCaseRequests().then(response => {
                        this.userCaseRequests = this.userCaseRequests.concat(response.data);
                    });
                });
            },
            /*GetAllOtherUserCaseRequests() {
                digitalevidence.GetAllOtherUserCaseRequests().then(response => {
                    this.userCaseRequests = response.data;
                });
            },*/
            PostCaseRequest() {
                digitalevidence.PostCaseRequest(this.newRequest).then(() => {
                }).catch(error => {
                    this.$mToast({
                        title: 'Error',
                        text:  error.response.data.error,
                        style: 'error'
                    });
                });
                this.areYouSureRequest = false;
                this.makeRequestModal = false;
                //this.GetAllApprovedUserCaseRequests()
                //this.GetAllOtherUserCaseRequests()
                this.GetUserCaseRequests();
            },
            GetUsersForRequest() {
                Iss.getUsersInGroup().then(response => {
                    this.users = response.data;
                })
            },
            CreateAUserRequest(){ //need single user not all users
                digitalevidence.PostCreateAUserRequest(this.selectedUser,this.currentCase._id).then(() =>{
                }).catch(error => {
                    this.$mToast({
                        title: 'Error',
                        text: error.response.data.error,
                        style: 'error'
                    });
                });
                this.userRequestModal = false;
                this.selectedUser = ''
            },
            openAddUser(item){
                this.userRequestModal = true
                this.currentCase = JSON.parse(JSON.stringify(item))
            },
            openAddCaseRequest(){
                this.makeRequestModal = true;
                Vue.set(this.newRequest, 'caseNumber', '')
                this.requestsVerifications = '';
            },
            dateify(time) {
                let created_date = time;
                let date = [];
                let months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                date.push(created_date.substring(0, 4));
                date.push(created_date.substring(5, 7));
                date.push(created_date.substring(8, 10));
                date.push(created_date.substring(11, 16));
                let mlong = months[parseInt(date[1])];

                function tConvert (time) {
                    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                    if (time.length > 1) {
                        time = time.slice (1);
                        time[5] = +time[0] < 12 ? 'AM' : 'PM';
                        time[0] = +time[0] % 12 || 12;
                    }
                    return time.join ('');
                }

                date[3] = tConvert(date[3]);
                return (mlong + " " + date[2] + ", " + date[0] + " at " + date[3] + " UTC");
            },
            openCaseEvidence(item){
                this.currentCase = JSON.parse(JSON.stringify(item));
                this.caseEvidenceModal = true;
            }
        }
    }
</script>

<style scoped>

</style>